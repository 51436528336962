var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-row",
        { staticClass: "my-4 ml-1" },
        [
          _c(
            "v-col",
            [
              _c(
                "v-row",
                { staticClass: "caption grey--text text--darken-1" },
                [_vm._v(_vm._s(_vm.$t("excavationStartDate")))]
              ),
              _c(
                "v-row",
                {
                  staticClass:
                    "grey--text text--darken-4 font-weight-medium body-2",
                },
                [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm
                          .moment(_vm.excavationDetails.work_date)
                          .format("MM/DD/YYYY")
                      ) +
                      " "
                  ),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-row",
        { staticClass: "my-4 ml-1" },
        [
          _c(
            "v-col",
            { attrs: { cols: "12", lg: "6", xl: "6", md: "6", sm: "12" } },
            [
              _c(
                "v-row",
                { staticClass: "caption grey--text text--darken-1" },
                [_vm._v(_vm._s(_vm.$t("Closest cross street")))]
              ),
              _c(
                "v-row",
                {
                  staticClass:
                    "grey--text text--darken-4 font-weight-medium body-2",
                },
                [_vm._v(" " + _vm._s(_vm.excavationDetails.cross1) + " ")]
              ),
            ],
            1
          ),
          _c(
            "v-col",
            {
              class: _vm.$vuetify.breakpoint.smAndDown ? "mt-4" : "",
              attrs: { cols: "12", lg: "6", xl: "6", md: "6", sm: "12" },
            },
            [
              _c(
                "v-row",
                { staticClass: "caption grey--text text--darken-1" },
                [_vm._v(_vm._s(_vm.$t("siteContactName")))]
              ),
              _c(
                "v-row",
                {
                  staticClass:
                    "grey--text text--darken-4 font-weight-medium body-2",
                },
                [_vm._v(" " + _vm._s(_vm.excavationDetails.contact) + " ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-row",
        { staticClass: "my-4 ml-1" },
        [
          _c(
            "v-col",
            { attrs: { cols: "12", lg: "6", xl: "6", md: "6", sm: "12" } },
            [
              _c(
                "v-row",
                { staticClass: "caption grey--text text--darken-1" },
                [_vm._v(_vm._s(_vm.$t("siteContactPhone")))]
              ),
              _c(
                "v-row",
                {
                  staticClass:
                    "grey--text text--darken-4 font-weight-medium body-2",
                },
                [
                  _vm._v(
                    " " + _vm._s(_vm.excavationDetails.contact_phone) + " "
                  ),
                ]
              ),
            ],
            1
          ),
          _c(
            "v-col",
            {
              class: _vm.$vuetify.breakpoint.smAndDown ? "mt-4" : "",
              attrs: { cols: "12", lg: "6", xl: "6", md: "6", sm: "12" },
            },
            [
              _c(
                "v-row",
                { staticClass: "caption grey--text text--darken-1" },
                [_vm._v(_vm._s(_vm.$t("siteContactEmail")))]
              ),
              _c(
                "v-row",
                {
                  staticClass:
                    "grey--text text--darken-4 font-weight-medium body-2",
                },
                [
                  _vm._v(
                    " " + _vm._s(_vm.excavationDetails.contact_email) + " "
                  ),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-row",
        { staticClass: "my-4 ml-1" },
        [
          _c(
            "v-col",
            { attrs: { cols: "12", lg: "6", xl: "6", md: "6", sm: "12" } },
            [
              _c(
                "v-row",
                { staticClass: "caption grey--text text--darken-1" },
                [_vm._v(_vm._s(_vm.$t("whoIsTheWork")))]
              ),
              _c(
                "v-row",
                {
                  staticClass:
                    "grey--text text--darken-4 font-weight-medium body-2",
                },
                [
                  _c("v-col", { staticClass: "pa-0" }, [
                    _c(
                      "span",
                      {
                        staticStyle: {
                          display: "block",
                          "word-wrap": "break-word",
                        },
                      },
                      [_vm._v(_vm._s(_vm.excavationDetails.done_for))]
                    ),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-col",
            {
              class: _vm.$vuetify.breakpoint.smAndDown ? "mt-4" : "",
              attrs: { cols: "12", lg: "6", xl: "6", md: "6", sm: "12" },
            },
            [
              _c(
                "v-row",
                { staticClass: "caption grey--text text--darken-1" },
                [_vm._v(_vm._s(_vm.$t("typeOfWork")) + " ")]
              ),
              _c(
                "v-row",
                {
                  staticClass:
                    "grey--text text--darken-4 font-weight-medium body-2",
                },
                [
                  _c("v-col", { staticClass: "pa-0" }, [
                    _c(
                      "span",
                      {
                        staticStyle: {
                          display: "block",
                          "word-wrap": "break-word",
                        },
                      },
                      [_vm._v(" " + _vm._s(_vm.excavationDetails.work_type))]
                    ),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-row",
        { staticClass: "my-4 ml-1" },
        [
          _c(
            "v-col",
            { attrs: { cols: "12", lg: "6", xl: "6", md: "6", sm: "12" } },
            [
              _c(
                "v-row",
                { staticClass: "caption grey--text text--darken-1" },
                [_vm._v(_vm._s(_vm.$t("locatorInstructions")))]
              ),
              _vm._l(_vm.excavationDetails.location, function (item) {
                return _c(
                  "v-row",
                  {
                    key: item,
                    staticClass:
                      "grey--text text--darken-4 font-weight-medium body-2",
                  },
                  [_c("div", [_vm._v(_vm._s(item))])]
                )
              }),
            ],
            2
          ),
        ],
        1
      ),
      _c(
        "v-row",
        { staticClass: "my-4 ml-1" },
        [
          _c(
            "v-col",
            [
              _c(
                "v-row",
                { staticClass: "caption grey--text text--darken-1" },
                [_vm._v(_vm._s(_vm.$t("excavationAreaOnProperty")))]
              ),
              _vm._l(_vm.excavationDetails.remarks, function (item) {
                return _c(
                  "v-row",
                  {
                    key: item,
                    staticClass:
                      "grey--text text--darken-4 font-weight-medium body-2",
                  },
                  [_c("div", [_vm._v(" " + _vm._s(item) + " ")])]
                )
              }),
            ],
            2
          ),
        ],
        1
      ),
      _c(
        "v-row",
        { staticClass: "my-4 ml-1" },
        [
          _c(
            "v-col",
            [
              _c(
                "v-row",
                { staticClass: "caption grey--text text--darken-1" },
                [_vm._v(_vm._s(_vm.$t("workingPole")))]
              ),
              _c(
                "v-row",
                {
                  staticClass:
                    "grey--text text--darken-4 font-weight-medium body-2",
                },
                [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.excavationDetails.workingAtPole ? "Yes" : "No"
                      ) +
                      " "
                  ),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm.excavationDetails.workingAtPole
        ? _c(
            "v-row",
            { staticClass: "my-4 ml-1" },
            [
              _c(
                "v-col",
                [
                  _c(
                    "v-row",
                    { staticClass: "caption grey--text text--darken-1" },
                    [_vm._v(_vm._s(_vm.$t("poleNumber")))]
                  ),
                  _c(
                    "v-row",
                    {
                      staticClass:
                        "grey--text text--darken-4 font-weight-medium body-2",
                    },
                    [
                      _vm._v(
                        " " + _vm._s(_vm.excavationDetails.poleNumberText) + " "
                      ),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _c(
        "v-row",
        { staticClass: "my-4 ml-1" },
        [
          _c(
            "v-col",
            [
              _c(
                "v-row",
                { staticClass: "caption grey--text text--darken-1" },
                [_vm._v(_vm._s(_vm.$t("exacStWalk")))]
              ),
              _c(
                "v-row",
                {
                  staticClass:
                    "grey--text text--darken-4 font-weight-medium body-2",
                },
                [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.excavationDetails.excav_st_walk ? "Yes" : "No"
                      ) +
                      " "
                  ),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-row",
        { staticClass: "my-4 ml-1" },
        [
          _c(
            "v-col",
            [
              _c(
                "v-row",
                { staticClass: "caption grey--text text--darken-1" },
                [_vm._v(_vm._s(_vm.$t("usasBoringLabel")))]
              ),
              _c(
                "v-row",
                {
                  staticClass:
                    "grey--text text--darken-4 font-weight-medium body-2",
                },
                [
                  _vm._v(
                    " " +
                      _vm._s(_vm.excavationDetails.boring ? "Yes" : "No") +
                      " "
                  ),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-row",
        { staticClass: "my-4 ml-1" },
        [
          _c(
            "v-col",
            [
              _c(
                "v-row",
                { staticClass: "caption grey--text text--darken-1" },
                [_vm._v(_vm._s(_vm.$t("pavementLabel")))]
              ),
              _c(
                "v-row",
                {
                  staticClass:
                    "grey--text text--darken-4 font-weight-medium body-2",
                },
                [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.excavationDetails.pavement_only ? "Yes" : "No"
                      ) +
                      " "
                  ),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-row",
        { staticClass: "my-4 ml-1" },
        [
          _c(
            "v-col",
            [
              _c(
                "v-row",
                { staticClass: "caption grey--text text--darken-1" },
                [_vm._v(_vm._s(_vm.$t("vaccumLabel")))]
              ),
              _c(
                "v-row",
                {
                  staticClass:
                    "grey--text text--darken-4 font-weight-medium body-2",
                },
                [
                  _vm._v(
                    " " +
                      _vm._s(_vm.excavationDetails.vacuum ? "Yes" : "No") +
                      " "
                  ),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-row",
        { staticClass: "my-4 ml-1" },
        [
          _c(
            "v-col",
            { attrs: { cols: "12", lg: "6", xl: "6", md: "6", sm: "12" } },
            [
              _c(
                "v-row",
                { staticClass: "caption grey--text text--darken-1" },
                [_vm._v(_vm._s(_vm.$t("premarksLabel")))]
              ),
              _vm._l(_vm.excavationDetails.how_delineated, function (item) {
                return _c(
                  "v-row",
                  {
                    key: item,
                    staticClass:
                      "grey--text text--darken-4 font-weight-medium body-2",
                  },
                  [_c("div", [_vm._v(_vm._s(item))])]
                )
              }),
            ],
            2
          ),
        ],
        1
      ),
      _c(
        "v-row",
        { staticClass: "my-4 ml-1" },
        [
          _c(
            "v-col",
            [
              _c(
                "v-row",
                { staticClass: "caption grey--text text--darken-1" },
                [_vm._v(_vm._s(_vm.$t("permitNumber")))]
              ),
              _c(
                "v-row",
                {
                  staticClass:
                    "grey--text text--darken-4 font-weight-medium body-2",
                },
                [_vm._v(" " + _vm._s(_vm.excavationDetails.permit) + " ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }