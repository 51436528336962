<template>
  <div>
    <v-row class="my-4 ml-1">
      <v-col>
        <v-row class="caption grey--text text--darken-1">{{
          $t("excavationStartDate")
        }}</v-row>
        <v-row class="grey--text text--darken-4 font-weight-medium body-2">
          {{ moment(excavationDetails.work_date).format("MM/DD/YYYY") }}
        </v-row>
      </v-col>
    </v-row>
    <v-row class="my-4 ml-1">
      <v-col cols="12" lg="6" xl="6" md="6" sm="12">
        <v-row class="caption grey--text text--darken-1">{{
          $t("Closest cross street")
        }}</v-row>
        <v-row class="grey--text text--darken-4 font-weight-medium body-2">
          {{ excavationDetails.cross1 }}
        </v-row>
      </v-col>
      <v-col
        cols="12"
        lg="6"
        xl="6"
        md="6"
        sm="12"
        :class="$vuetify.breakpoint.smAndDown ? 'mt-4' : ''"
      >
        <v-row class="caption grey--text text--darken-1">{{
          $t("siteContactName")
        }}</v-row>
        <v-row class="grey--text text--darken-4 font-weight-medium body-2">
          {{ excavationDetails.contact }}
        </v-row>
      </v-col>
    </v-row>
    <v-row class="my-4 ml-1">
      <v-col cols="12" lg="6" xl="6" md="6" sm="12">
        <v-row class="caption grey--text text--darken-1">{{
          $t("siteContactPhone")
        }}</v-row>
        <v-row class="grey--text text--darken-4 font-weight-medium body-2">
          {{ excavationDetails.contact_phone }}
        </v-row>
      </v-col>
      <v-col
        cols="12"
        lg="6"
        xl="6"
        md="6"
        sm="12"
        :class="$vuetify.breakpoint.smAndDown ? 'mt-4' : ''"
      >
        <v-row class="caption grey--text text--darken-1">{{
          $t("siteContactEmail")
        }}</v-row>
        <v-row class="grey--text text--darken-4 font-weight-medium body-2">
          {{ excavationDetails.contact_email }}
        </v-row>
      </v-col>
    </v-row>
    <v-row class="my-4 ml-1">
      <v-col cols="12" lg="6" xl="6" md="6" sm="12">
        <v-row class="caption grey--text text--darken-1">{{
          $t("whoIsTheWork")
        }}</v-row>
        <v-row class="grey--text text--darken-4 font-weight-medium body-2">
          <v-col class="pa-0">
            <span style="display: block; word-wrap: break-word">{{
              excavationDetails.done_for
            }}</span>
          </v-col>
        </v-row>
      </v-col>
      <v-col
        cols="12"
        lg="6"
        xl="6"
        md="6"
        sm="12"
        :class="$vuetify.breakpoint.smAndDown ? 'mt-4' : ''"
      >
        <v-row class="caption grey--text text--darken-1"
          >{{ $t("typeOfWork") }}
        </v-row>
        <v-row class="grey--text text--darken-4 font-weight-medium body-2">
          <v-col class="pa-0">
            <span style="display: block; word-wrap: break-word">
              {{ excavationDetails.work_type }}</span
            >
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row class="my-4 ml-1">
      <v-col cols="12" lg="6" xl="6" md="6" sm="12">
        <v-row class="caption grey--text text--darken-1">{{
          $t("locatorInstructions")
        }}</v-row>
        <v-row
          v-for="item in excavationDetails.location"
          :key="item"
          class="grey--text text--darken-4 font-weight-medium body-2"
        >
          <div>{{ item }}</div>
        </v-row>
      </v-col>
    </v-row>
    <v-row class="my-4 ml-1">
      <v-col>
        <v-row class="caption grey--text text--darken-1">{{
          $t("excavationAreaOnProperty")
        }}</v-row>
        <v-row
          v-for="item in excavationDetails.remarks"
          :key="item"
          class="grey--text text--darken-4 font-weight-medium body-2"
        >
          <div>
            {{ item }}
          </div>
        </v-row>
      </v-col>
    </v-row>
    <v-row class="my-4 ml-1">
      <v-col>
        <v-row class="caption grey--text text--darken-1">{{
          $t("workingPole")
        }}</v-row>
        <v-row class="grey--text text--darken-4 font-weight-medium body-2">
          {{ excavationDetails.workingAtPole ? "Yes" : "No" }}
        </v-row>
      </v-col>
    </v-row>
    <v-row class="my-4 ml-1" v-if="excavationDetails.workingAtPole">
      <v-col>
        <v-row class="caption grey--text text--darken-1">{{
          $t("poleNumber")
        }}</v-row>
        <v-row class="grey--text text--darken-4 font-weight-medium body-2">
          {{ excavationDetails.poleNumberText }}
        </v-row>
      </v-col>
    </v-row>
    <v-row class="my-4 ml-1">
      <v-col>
        <v-row class="caption grey--text text--darken-1">{{
          $t("exacStWalk")
        }}</v-row>
        <v-row class="grey--text text--darken-4 font-weight-medium body-2">
          {{ excavationDetails.excav_st_walk ? "Yes" : "No" }}
        </v-row>
      </v-col>
    </v-row>
    <v-row class="my-4 ml-1">
      <v-col>
        <v-row class="caption grey--text text--darken-1">{{
          $t("usasBoringLabel")
        }}</v-row>
        <v-row class="grey--text text--darken-4 font-weight-medium body-2">
          {{ excavationDetails.boring ? "Yes" : "No" }}
        </v-row>
      </v-col>
    </v-row>
    <v-row class="my-4 ml-1">
      <v-col>
        <v-row class="caption grey--text text--darken-1">{{
          $t("pavementLabel")
        }}</v-row>
        <v-row class="grey--text text--darken-4 font-weight-medium body-2">
          {{ excavationDetails.pavement_only ? "Yes" : "No" }}
        </v-row>
      </v-col>
    </v-row>
    <v-row class="my-4 ml-1">
      <v-col>
        <v-row class="caption grey--text text--darken-1">{{
          $t("vaccumLabel")
        }}</v-row>
        <v-row class="grey--text text--darken-4 font-weight-medium body-2">
          {{ excavationDetails.vacuum ? "Yes" : "No" }}
        </v-row>
      </v-col>
    </v-row>
    <v-row class="my-4 ml-1">
      <v-col cols="12" lg="6" xl="6" md="6" sm="12">
        <v-row class="caption grey--text text--darken-1">{{
          $t("premarksLabel")
        }}</v-row>
        <v-row
          v-for="item in excavationDetails.how_delineated"
          :key="item"
          class="grey--text text--darken-4 font-weight-medium body-2"
        >
          <div>{{ item }}</div>
        </v-row>
      </v-col>
    </v-row>
    <v-row class="my-4 ml-1">
      <v-col>
        <v-row class="caption grey--text text--darken-1">{{
          $t("permitNumber")
        }}</v-row>
        <v-row class="grey--text text--darken-4 font-weight-medium body-2">
          {{ excavationDetails.permit }}
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import moment from "moment";
export default {
  props: {
    excavationDetails: {
      type: Object,
      default: () => {},
    },
  },
  created() {
    this.moment = moment;
  },
};
</script>

<style lang="scss" scoped></style>
